import { useEffect } from "react";
import Modal from "components/Modal";
import UploadImage from "components/forms/UploadImage";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useCreateChannelMutation, useEditChannelMutation } from "services";
import { LoadingButton } from "@mui/lab";
import ValidatedInput from "components/forms/ValidatedInput";
import AutoCompleteField from "components/forms/AutoCompleteField";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { CircularProgress } from "@mui/material";

const ManageChannelModal = ({ open, close, isEditing, channel }) => {
 const [searchParams] = useSearchParams();
 const id = searchParams.get("campaign");
 const [createChannel, { isLoading }] = useCreateChannelMutation();
 const [editChannel, { isLoading: isEditingChannel }] = useEditChannelMutation();
 const methods = useForm();

 useEffect(() => {
  if (isEditing) {
   methods.reset({
    ...channel,
    icon: channel?.icon || "",
   });
  }
 }, [channel, isEditing, methods]);

 const handleSubmit = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("metric_name", data.metric_name);
  if (typeof data.icon === "object") formData.append("icon", data.icon[0]);
  formData.append("campaign_source_id", id);

  try {
   const res = isEditing
    ? await editChannel({ id: channel?.id, body: formData }).unwrap()
    : await createChannel(formData).unwrap();
   showToast(res?.message);
   close();
   methods.reset();
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <Modal title={isEditing ? "Edit Channel" : "Add Channel"} openModal={open} closeModal={close}>
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
     <ValidatedInput name="name" label="Channel name" />
     <AutoCompleteField
        name="metric_name"
        label="Metric Name"
        selectOption={[
          {
            label: "Followers",
            value: "followers"
          },
          {
            label: "Friends",
            value: "friends"
          },
          {
            label: "Listeners",
            value: "listeners"
          },
          {
            label: "Subscribers",
            value: "subscribers"
          },
          {
            label: "Connections",
            value: "connections"
          },
          {
            label: "Members",
            value: "members"
          },
          {
            label: "Contacts",
            value: "contacts"
          },
          {
            label: "Visitors",
            value: "visitors"
          }
        ]}
        placeholder="Select Metric Name"
      />
     <UploadImage name="icon" label="Channel Icon" required={isEditing ? false : true} />
     {/* {isEditing && (
            <div className="my-6">
              <p className="text-grey30 text-sm mb-2">Status</p>
              <ToggleSwitch
                name="status"
                toggleState={status === "active"}
                setToggleState={() => {
                  methods.setValue(
                    "status",
                    status === "active" ? "inactive" : "active"
                  );
                }}
              />
            </div>
          )} */}
     {/* {isEditing && (
            <Stack direction="row" spacing={1} mb={2}>
              <Chip
                variant="outlined"
                label="Inactive"
                onClick={() => setStatus("inactive")}
                sx={{color: status === "inactive" ? "#8937CE" : "#A2A2B9"}}
              />
              <Chip
                label="Active"
                variant="outlined"
                sx={{color: status === "active" ? "#8937CE" : "#A2A2B9"}}
                onClick={() => setStatus("active")}
              />
            </Stack>
          )} */}
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       /> 
      }
      loading={isLoading || isEditingChannel}
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
     >
      {isEditing ? "Save Changes" : "Add Channel"}
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
};

export default ManageChannelModal;
