import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import DropDownWrapper from "components/DropDownWrapper";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import SuspendUserModal from "components/Modal/SuspendUserModal";
import DeleteUserModal from "components/Modal/DeleteUserModal";
import UnsuspendUsermodal from "components/Modal/UnsuspendUsermodal";
import VerifyUserModal from "components/Modal/VerifyUserModal";

const InfluencerCta = ({ user }) => {
 const [openSuspendModal, setOpenSuspendModal] = useState(false);
 const [openUnSuspendModal, setOpenUnsuspendModal] = useState(false);
 const [openDeleteModal, setOpenDeleteModal] = useState(false);
 const [showVerifyModal, setShowVerifyModal] = useState(false);

 const isSuspended = user?.status?.toLowerCase() === "suspended";

 return (
  <>
   <div className="flex gap-2 items-center">
    {user && user?.user_type === "influencer" && !user?.is_verified && (
    <Button 
    variant="contained"
    onClick={() => setShowVerifyModal(true)}
     sx={{ padding: "0px" }}>
        Verify Influencer
    </Button>
    )}

    {/* <DropDownWrapper
                    className="more-actions"
                    position="right"
                    border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
                    closeOnBtnClick={false}
                    action={
                        <button
                            type="button"
                            className="flex h-[52px] w-[52px] items-center justify-center rounded-2xl bg-[#F2F4F7]"
                        >
                            <MoreHorizOutlinedIcon color="primary" fontSize="large" />
                        </button>
                    }
                > */}
    {/* <Button
                    onClick={() => (isSuspended ? handleActivateAccount() : setOpenSuspendModal(true))}
                    color="errorInverse"
                    variant='contained'
                    startIcon={<DoDisturbAltRoundedIcon />}
                >
                    {isSuspended ? 'Unsuspend' : 'Suspend'} Account
                </Button> */}
    {/* </DropDownWrapper> */}

    <DropDownWrapper
     className="more-actions"
     position="right"
     border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
     closeOnBtnClick={false}
     action={
      <button type="button" className="flex h-[52px] w-[52px] items-center justify-center rounded-2xl bg-[#F2F4F7]">
       <MoreHorizOutlinedIcon color="primary" fontSize="large" />
      </button>
     }
    >
     <Button
      onClick={() => (isSuspended ? setOpenUnsuspendModal(true) : setOpenSuspendModal(true))}
      color="inherit"
      startIcon={<DoDisturbAltRoundedIcon />}
     >
      {isSuspended ? "Unsuspend" : "Suspend"} Account
     </Button>
     {/* <Button onClick={() => setOpenDeleteModal(true)} className="!text-[#D61E34]" startIcon={<TrashIcon />}>
      Delete User
     </Button> */}
    </DropDownWrapper>
   </div>
   <SuspendUserModal user={user} setOpenModal={setOpenSuspendModal} openModal={openSuspendModal} />
   <DeleteUserModal user={user} setOpenModal={setOpenDeleteModal} openModal={openDeleteModal} />
   <UnsuspendUsermodal user={user} setOpenModal={setOpenUnsuspendModal} openModal={openUnSuspendModal} />
   <VerifyUserModal user={user} setOpenModal={setShowVerifyModal} openModal={showVerifyModal} />
  </>
 );
};

export default InfluencerCta;
