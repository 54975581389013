const ChannelInfoCard = ({name, url, icon}) => {
  return (
    <div className="border-[1px] border-borderColor rounded-[8px] p-4 w-full ">
      <div className="flex gap-4">
        {icon && (
          <div className="h-[20px] w-[20px] ">
            <img src={icon} alt="Channel Icon" />
          </div>
        )}
        <div className="">
          <p className="text-prop font-medium capitalize text-grey text-[14px] ">
            {name}
          </p>
          <a href={url} target="_blank" className="text-prop font-semibold text-grey10 text-[1rem] mt-[8px] hover:underline hover:text-primary">
            {url}
          </a>
        </div>
      </div>
    </div>
  );
};
export default ChannelInfoCard;
